export default {
  methods: {
    async dialog(title, content, confirm, cancel, maxWidth) {
      return await this.$store.state.appRootServices.dialog(
        title,
        content,
        confirm,
        cancel,
        maxWidth
      );
    }
  }
};
